/* You can add global styles to this file, and also import other style files */

$roboto-font-path: "~roboto-fontface/fonts" !default;
@import "~roboto-fontface/css/roboto/sass/roboto-fontface";

$material-icons-font-path: '~material-icons/iconfont/';
@import '~material-icons/iconfont/material-icons.scss';

@import "src/styles/shared";
@import "src/styles/vimscore-theme";

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.basic-container {
  padding: 30px 30px 0 30px;
}

.vs-horizontal {
  .mat-radio-button ~ .mat-radio-button {
    margin-left: 15px;
  }
}

mat-radio-group.vs-vertical {
  display: flex;
  flex-direction: column;
}

mat-radio-group.vs-vertical {
  .vs-radio-group-down-button {
    margin: 5px 0;
  }

  > :first-child {
    margin-top: 0 !important;
    margin-left: 0 !important;
  }

  > :last-child {
    margin-bottom: 0 !important;
    margin-right: 0 !important;
  }
}

// ------------------------------------------------------------------------
// Table

.vs-table-wrapper {
  overflow-x: auto;
  white-space: pre;
}

table.full-width {
  width: 100%;
}

table.full-width-scroll {
  display: block;
  overflow-x: auto;
  white-space: nowrap;
}

table.vs-table {
  $padding: 20px;
  margin-bottom: 16px;

  thead th:not(:first-child):not(:last-child),
  thead td:not(:first-child):not(:last-child),
  tbody th:not(:first-child):not(:last-child),
  tbody td:not(:first-child):not(:last-child) {
    padding-left: $padding;
    padding-right: $padding;
  }

  thead th:first-child,
  thead td:first-child,
  tbody th:first-child,
  tbody td:first-child {
    padding-right: $padding;
  }
  // If it is the last child and *not* also the first child
  thead th:last-child:not(:first-child),
  thead td:last-child:not(:first-child),
  tbody th:last-child:not(:first-child),
  tbody td:last-child:not(:first-child) {
    padding-left: $padding;
  }
}

.vs-odd-row {
  background-color: lightgrey;
}

.vs-even-row {
  background-color: white;
}

table.vs-grey-odd tr:nth-child(2n) {
  background: whitesmoke;
}

// ------------------------------------------------------------------------
// https://docs.etrade.design/definition-lists

dl.vs-dl {
  display: flex;
  flex-flow: row wrap;

  dt {
    flex-basis: 20%;
    padding: 5px 10px 3px 0;
    text-align: left;
  }

  dd {
    flex-basis: 70%;
    flex-grow: 1;
    margin: 0;
    padding: 5px 10px;
  }

  dt:first-child, dt:first-child + dd {
    border-top: 1px solid lightgrey;
  }

  dt, dd {
    border-bottom: 1px solid lightgrey;
    line-height: 36px;
  }
}

dl.vs-dl-50-50 {
  dt {
    // Needs some extra space for the paddings
    flex-basis: 40%;
  }

  dd {
    flex-basis: 50%;
  }
}
