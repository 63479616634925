// Copied from app_theme.dart

$strength-color: #EC6568;
$endurance-color: #0099CC;
$balance-color: #6FBE95;
$flexibility-color: #F39669;

.capacity-STRENGTH {
  color: $strength-color;
}

.capacity-ENDURANCE {
  color: $endurance-color;
}

.capacity-BALANCE {
  color: $balance-color;
}

.capacity-FLEXIBILITY {
  color: $flexibility-color;
}

// From home page
$light-green: #67c194;
$dark-green: #006667;
